<template>
  <div class="content">
    <div>
      <a-card style="width: 100%; border-radius: 4px">
        <div class="top-title">
          <!-- <a-icon type="smile" style="font-size: 38px; color: rgb(127, 98, 173); margin-right: 20px" /> -->
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="table"
            class="svg-inline--fa fa-table fa-fw fa-lg"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="32px"
            height="32px"
            style="margin-right:5px"
          >
            <path
              fill="currentColor"
              d="M64 256V160H224v96H64zm0 64H224v96H64V320zm224 96V320H448v96H288zM448 256H288V160H448v96zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"
            ></path>
          </svg>
          {{ $t('导出.读数导出标题') }}
        </div>
        <div class="top-middle">
          {{ $t('导出.读数导出介紹') }}
        </div>
        <div style="margin-top: 16px">
          <!--<a-input v-model="form.serialNumber" :placeholder="$t('导出.输入提示')" @keydown.enter="searchExport"/>-->
          <a-select
            size="large"
            show-search
            :allowClear="true"
            v-model="form.serialNumber"
            style="width: 100%"
            :placeholder="$t('导出.输入提示')"
            @keydown.enter="searchExport"
            option-filter-prop="children"
            :filter-option="filterOption"
            @focus="getfocus"
            @change="selectChange"
          >
            <a-select-option v-for="(item, index) in memoryList" :key="index">
              {{ item.searchWord }}
            </a-select-option>
          </a-select>
        </div>
        <div>
          <a-button class="btn" @click="searchExport" :disabled="form.serialNumber == ''"
            >{{ $t('通用.按钮.查询') }}
            <!-- <a-icon type="plus" style="font-size: 20px; color: rgb(127, 98, 173); margin-right: 20px" /> -->
          </a-button>
        </div>
      </a-card>
    </div>
    <div style="margin-top: 16px" v-show="show">
      <a-card style="width: 100%; border-radius: 4px">
        <div>
          <a-row :gutter="16">
            <a-col :span="7">
              <!--<a-input v-model="queryParam.serialNumber" :placeholder="$t('导出.序列号')" size="large" />-->
              <a-select
                size="large"
                :allowClear="true"
                show-search
                v-model="queryParam.serialNumber"
                :placeholder="$t('导出.序列号')"
                style="width: 100%"
                @keydown.enter="searchExport"
                option-filter-prop="children"
                :filter-option="filterOption1"
                @focus="getfocus"
                @change="handleChange"
              >
                <a-select-option v-for="(item, index) in memoryList" :key="index">
                  {{ item.searchWord }}
                </a-select-option>
              </a-select>

            </a-col>
            <!--<a-col :span="7">-->
            <!--<a-input v-model="queryParam.startBeginDate" :placeholder="$t('学习模块.类型')" size="large" />-->
            <!--&lt;!&ndash; <a-select v-model="form.type" style="width: 100%" :placeholder="$t('学习模块.类型')" size="large">-->
            <!--<a-select-option value="Clinical Study">Clinical Study</a-select-option>-->
            <!--<a-select-option value="Virtual Study">Virtual Study</a-select-option>-->
            <!--</a-select> &ndash;&gt;-->
            <!--</a-col>-->
            <!--<a-col :span="7">-->
            <!--<a-input v-model="queryParam.startEndDate" :placeholder="$t('学习模块.序列号')" size="large" />-->
            <!--</a-col>-->

            <a-col :md="6" :sm="24">
              <a-form-item prop="startBeginDate">
                <a-date-picker
                  :placeholder="$t('系统用户.开始时间')"
                  style="width: 100%"
                  v-model="queryParam.startBeginDate"
                  :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                  allow-clear
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item prop="startEndDate">
                <a-date-picker
                  :placeholder="$t('系统用户.结束时间')"
                  style="width: 100%"
                  v-model="queryParam.startEndDate"
                  :show-time="{ defaultValue: moment('23:59:59', 'HH:mm:ss') }"
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                  allow-clear
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-button class="btn" @click="search">{{ $t('算法运行.搜索') }}</a-button>
              <a-button class="btn btn1" @click="reset">{{ $t('学习模块.清空') }}</a-button>
            </a-col>
          </a-row>
        </div>
        <div style="margin-top: 16px">
          <a-table
            :loading="loading"
            :size="tableSize"
            rowKey="id"
            :columns="columns"
            :data-source="list"
            :pagination="false"
            :bordered="tableBordered"
          >

            <span slot="serialNumber" slot-scope="text, record" @click="handlerowclick(record)">
              {{ record.serialNumber }}
            </span>

            <span slot="createTime" slot-scope="text, record">
              {{ parseTime(record.createTime) }}
            </span>
            <span slot="operation" slot-scope="text, record">
              <!-- handleExport(record) -->
              <a @click="$refs.modalRefs.showModal(record)" style="color: rgb(127, 98, 173)">
                {{ $t('通用.按钮.下载') }}
                <a-icon type="download" />
              </a>
            </span>


            <template slot="introducedAt" slot-scope="text, record">
              <a-tooltip>
                <template slot="title">
                  {{ record.introducedAt.substring(5, 7) }}/{{ record.introducedAt.substring(8, 10) }}/{{record.introducedAt.substring(0, 4) }}
                  {{ record.introducedAt.substring(11, 16)}}
                  <span v-if="record.introducedAt.substring(11, 13) <= 12">
                    AM
                  </span>
                  <span v-if="record.introducedAt.substring(11, 13) > 12">
                    PM
                  </span>
                </template>
                {{ record.introducedAt.substring(5, 7) }}/{{ record.introducedAt.substring(8, 10) }}/{{record.introducedAt.substring(2, 4) }}
              </a-tooltip>
            </template>

            <template slot="firstIntroducedAt" slot-scope="text, record">
              <a-tooltip>
                <template slot="title">
                  {{ record.firstIntroducedAt.substring(5, 7) }}/{{ record.firstIntroducedAt.substring(8, 10) }}/{{record.firstIntroducedAt.substring(0, 4) }}
                  {{ record.firstIntroducedAt.substring(11, 16)}}
                  <span v-if="record.firstIntroducedAt.substring(11, 13) <= 12">
                    AM
                  </span>
                  <span v-if="record.firstIntroducedAt.substring(11, 13) > 12">
                    PM
                  </span>
                </template>
                {{ record.firstIntroducedAt.substring(5, 7) }}/{{ record.firstIntroducedAt.substring(8, 10) }}/{{record.firstIntroducedAt.substring(2, 4) }}
              </a-tooltip>
            </template>


            <template slot="lastIntroducedAt" slot-scope="text, record">
              <a-tooltip>
                <template slot="title">
                  {{ record.lastIntroducedAt.substring(5, 7) }}/{{ record.lastIntroducedAt.substring(8, 10) }}/{{record.lastIntroducedAt.substring(0, 4) }}
                  {{ record.lastIntroducedAt.substring(11, 16)}}
                  <span v-if="record.lastIntroducedAt.substring(11, 13) <= 12">
                    AM
                  </span>
                  <span v-if="record.lastIntroducedAt.substring(11, 13) > 12">
                    PM
                  </span>
                </template>
                {{ record.lastIntroducedAt.substring(5, 7) }}/{{ record.lastIntroducedAt.substring(8, 10) }}/{{record.lastIntroducedAt.substring(2, 4) }}
              </a-tooltip>
            </template>


          </a-table>
        </div>
      </a-card>
    </div>

    <!-- Modal -->
    <modal  ref="modalRefs"/>
  </div>
</template>

<script>
import { queryExport, SearchpageExport, Export,queryPcbaExport } from '@/api/export/export'
import { pageStudies } from '@/api/studies/studies'
import moment from 'moment'
import { pageMemory, listMemory, delMemory, listSixMemory } from '@/api/memory/memory'
import Modal from './cpns/model.vue'
export default {
  components: {
       Modal 
  },

  data() {
    return {
      list: [],
      memoryList: [],
      form: {
        serialNumber: undefined,
        startBeginDate: undefined,
        startEndDate: undefined,
        pageNum: 1,
        pageSize: 10,
      },
      // 查询参数
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        serialNumber: undefined,
        startBeginDate: undefined,
        startEndDate: undefined,
        firstIntroducedAt: undefined,
        lastIntroducedAt: undefined,
      },
      columns: [
        {
          title: this.$t('导出.序列号'),
          dataIndex: 'serialNumber',
          key: 'serialNumber',
          scopedSlots: { customRender: 'serialNumber' },
        },
        {
          title: this.$t('导出.记录时间'),
          dataIndex: 'introducedAt',
          key: 'introducedAt',
          scopedSlots: { customRender: 'introducedAt' },
        },
        {
          title: this.$t('导出.fw版本号'),
          dataIndex: 'fwVersion',
          key: 'fwVersion',
        },
        {
          title: this.$t('导出.读取次数'),
          dataIndex: 'readings',
          key: 'readings',
        },
        {
          title: this.$t('导出.首次记录时间'),
          dataIndex: 'firstIntroducedAt',
          key: 'firstIntroducedAt',
          scopedSlots: { customRender: 'firstIntroducedAt' },
        },
        {
          title: this.$t('导出.最后一次记录时间'),
          dataIndex: 'lastIntroducedAt',
          key: 'lastIntroducedAt',
          scopedSlots: { customRender: 'lastIntroducedAt' },
        },
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
        },
      ],
      show: false,
    }
  },
  created() {
    this.getList();
  },
  methods: {
    moment,
    /** 查询列表 */
    getList() {
      this.loading = true
      pageStudies(this.queryParam).then((response) => {
        this.list = response.data.records
        // this.total = response.data.total
        this.loading = false
      })
    },
    searchExport() {
      this.show = true
      this.loading = true
      queryPcbaExport(this.form).then((res) => {
        console.log('export', res)
        this.list = res.data.records
        this.loading = false
      })
    },

    /** 导出按钮操作 */
    handleExport(record) {
      let serialNumber = record.serialNumber;
      let firstIntroducedAt = record.firstIntroducedAt;
      let lastIntroducedAt = record.lastIntroducedAt
      var that = this
      that.queryParam.serialNumber = record.serialNumber;
      that.queryParam.firstIntroducedAt = firstIntroducedAt;
      that.queryParam.lastIntroducedAt = lastIntroducedAt;

      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk() {
          that.download('deviceReading/deviceReading/export', that.queryParam, `${serialNumber}.csv`)
        },
        onCancel() {},
      })
    },

    search() {
      queryPcbaExport(this.queryParam).then((res) => {
        console.log('export', res)
        this.list = res.data.records
        this.loading = false
      })
    },

    reset() {
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        serialNumber: undefined,
        startBeginDate: undefined,
        startEndDate: undefined,
      }

      queryPcbaExport(this.queryParam).then((res) => {
        console.log('export', res)
        this.list = res.data.records
        this.loading = false
      })
    },

    //获得焦点
    getfocus() {
      this.sixMemoryList()
    },
    //失去焦点
    losefocus() {
      this.memoryList = []
    },
    //查询联词搜索
    sixMemoryList() {
      let param = {}
      param.meunType = 'PCBA'
      listSixMemory(param).then((response) => {
        this.memoryList = response.data
      })
    },
    filterOption(input, option) {
      this.form.serialNumber = input
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
     filterOption1(input, option) {
      this.queryParam.serialNumber = input
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    selectChange(e){
         this.form.serialNumber = this.memoryList[e].searchWord
    },
    handleChange(e){
         this.queryParam.serialNumber = this.memoryList[e].searchWord
    },
    handlerowclick(record) {
      this.$router.push({
        path: '/sensor/sub-sensor',
        query: {
          serialNumber: record.serialNumber,
        },
      });
     //  return {
     //      on: {
     //        click: () => {
     //        //行单击
     //        this.$router.push({
     //        path: '/sensor/sub-sensor',
     //        query: {
     //          serialNumber: record.serialNumber,
     //        },
     //      })
     //    },
     //  },
     // }
    },

  },
}
</script>

<style scoped lang="less">
.content {
  width: 100%;
  height: 100%;

  .top-title {
    align-items: center;
    display: flex;
    font-size: 1.6rem;
    font-weight: 600;
    color: #212529;
  }

  .top-middle {
    margin-top: 8px;
    font-size: 16px;
    color: #212529;
    font-family: Poppins, sans-serif;
  }

  .btn {
    color: rgb(127, 98, 173);
    background-color: rgb(127, 98, 173);
    font-weight: 500;
    font-size: 16px;
    margin-top: 16px;
    border-radius: 0.25rem;
    // width: 126px;
    height: 38px;
    color: #fff;
  }
  .btn1 {
    margin-top: 0px !important;
  }

  .btn[disabled] {
    background-color: rgb(207, 195, 226) !important;
  }
}
</style>