<template>
  <div>
    <a-modal title="Download" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk">
      <a-form :form="form">
        <a-form-item>
          <a-input v-model="form.serialNumber" disabled style="width: 100%" />
        </a-form-item>
        <a-form-item>
          <a-date-picker
            :placeholder="$t('系统用户.开始时间')"
            style="width: 100%"
            v-model="form.startRecordedAt"
            format="YYYY-MM-DD HH:mm:ss"
            valueFormat="YYYY-MM-DD HH:mm:ss"
            allow-clear
          />
        </a-form-item>
        <a-form-item>
          <a-date-picker
            :placeholder="$t('系统用户.结束时间')"
            style="width: 100%"
            v-model="form.endRecordedAt"
            format="YYYY-MM-DD HH:mm:ss"
            valueFormat="YYYY-MM-DD HH:mm:ss"
            allow-clear
          />
        </a-form-item>
      </a-form>

      <template #footer>
        <a-button key="back" @click="handleCancel" class="cancelBtn">{{ $t('通用.按钮.取消') }}</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk" class="downBtn">{{
          $t('通用.按钮.下载')
        }}</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {},
      row: {},
    }
  },
  methods: {
    showModal(row) {
      this.visible = true
      this.form.serialNumber = row.serialNumber;
      this.form.firstIntroducedAt = row.firstIntroducedAt;
      this.form.lastIntroducedAt = row.lastIntroducedAt
    },
    handleOk(e) {

      this.confirmLoading = true
      let serialNumber = this.form.serialNumber;
      var that = this;
      that.download('deviceReading/deviceReading/export', that.form, `${serialNumber}.csv`)
      this.handleCancel()
      this.confirmLoading = false
    },
    handleCancel(e) {
      console.log('Clicked cancel button')
      this.visible = false
    },
  },
}
</script>
<style scoped lang="less">
.cancelBtn {
  border: none !important;
  color: rgb(127, 98, 173);
  font-weight: 700;
  font-size: 16px;
}
.downBtn {
  background-color: rgb(127, 98, 173);
  border: none !important;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  border-radius: .25rem;
}
</style>
